// base
$BASE: (
  color: #3c3c3c,
  color-link: #3c3c3c,
  color-page: #3b3b3b,
  color-page-link: #0068B4,
  font-size: 16,
  line-height: 1.6,
) !default;

// color
$COLOR: (
  strong: hsl(359, 75%, 57%),
  blue: hsl(204, 70%, 53%),
  green: hsl(168, 76%, 42%),
) !default;

$COLOR_MAIN: #e2f4fc;
$COLOR_WHITE: #fff;
$COLOR_PINK: #ea6d9f;
$COLOR_CATEGORY1: #00b7de;
$COLOR_CATEGORY2: #66be96;

// brake-point
$BRAKE_POINT: (
  sp: 600px,
  tbP: 768px,
  tbL: 992px,
  pc: 1200px,
) !default;

// container-padding
$PADDING: (
  container: 15,
) !default;

// container-size
$CONTAINER: (
  sm: #{map_get($PADDING, container) * 2 + 750px},
  md: #{map_get($PADDING, container) * 2 + 970px},
  lg: #{map_get($PADDING, container) * 2 + 1200px},
) !default;

// font-family
$FONT_BASE: 'メイリオ', Meiryo, -apple-system, BlinkMacSystemFont, 'Hiragino Kaku Gothic ProN', sans-serif;
$FONT_M_PLUS: 'M PLUS 1p', sans-serif;
$FONT_ALPHA: Arial, Helvetica, sans-serif;
$FONT_MINCHO: '游明朝体', 'Yu Mincho', 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', serif;
$FONT_ICON: 'Material Icons';
