@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;
@forward "~swiper/swiper";

/*
top
*/
$PAGE_ID: top;
$PATH: '../';
$COMMON_PATH: '#{$PATH}img/common/';
$IMAGE_PATH: '#{$PATH}img/#{$PAGE_ID}/';

.p-top-mv .swiper-container {
  margin: 0 auto;
  max-width: 1272px;
  overflow: visible;

  @include mixin.mq-max(1350) {
    max-width: 90vw;
  }

  @include mixin.mq-tbP {
    max-width: 100%;
  }
}
.p-top-mv .swiper-slide {
  max-width: 1272px;
  padding: 0 6px;

  @include mixin.mq-tbP {
    padding: 0;
  }
}
.p-top-mv .swiper-slide img {
  height: 500px;
  @include mixin.object-fit(cover, center center);

  @include mixin.mq-tbP {
    @include mixin.vw(height, 754, 750);
  }
}

.p-top-mv__text {
  bottom: 20px;
  left: 55px;
  position: absolute;
  z-index: 2;

  @include mixin.mq-pc {
    max-width: 30vw;
  }

  @include mixin.mq-tbP {
    bottom: 25px;
    left: 0;
    max-width: 100%;
    padding: 0 20px;
    width: 100%;
  }
}

.p-top-mv__arrow {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  @include mixin.mq-tbP {
    width: 32px;
  }
}
.p-top-mv__arrow img {
  vertical-align: middle;
}
.p-top-mv__arrow--prev {
  left: -32.5px;

  @include mixin.mq-tbP {
    left: 5px;
  }
}
.p-top-mv__arrow--next {
  right: -32.5px;

  @include mixin.mq-tbP {
    right: 5px;
  }
}

.p-top-mv__pagination {
  align-items: center;
  bottom: 38px;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  z-index: 1;
  width: 100%;

  @include mixin.mq-tbP {
    bottom: 10px;
  }
}
.p-top-mv__pagination > span {
  border: 2px solid variables.$COLOR_WHITE;
  border-radius: 100px;
  height: 16px;
  margin: 0 8px;
  width: 16px;

  @include mixin.mq-tbP {
    border-width: 1px;
    height: 10px;
    margin: 0 5px;
    width: 10px;
  }
}
.p-top-mv__pagination > span[class*='active'] {
  background-color: variables.$COLOR_WHITE;
}

.p-top-section1 {
  background: {
    image: url("#{mixin.set_image_path('top/bg1_pc.png')}");
    position: center;
    repeat: no-repeat;
    size: cover;
  }
}
.p-top-section1__inner {
  padding: 120px 0 60px;
  position: relative;

  @include mixin.mq-tbP {
    padding: 40px 0;
  }
}
.p-top-section1-title {
  font-family: variables.$FONT_M_PLUS;
  @include mixin.fz(34);
  letter-spacing: 0.1em;
  margin-bottom: 40px;
  position: relative;
  text-align: center;
  z-index: 1;

  @include mixin.mq-tbP {
    @include mixin.fz(20);
    margin-bottom: 25px;
  }
}
.p-top-section1-title__inner {
  display: inline-block;
  padding: 0 175px;
  position: relative;

  @include mixin.mq-tbP {
    padding: 0;
  }

  &::before,
  &::after {
    background: {
      size: contain;
      position: center;
      repeat: no-repeat;
    }
    content: '';
    height: 42px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 145px;

    @include mixin.mq-tbP {
      height: 21px;
      top: 0;
      transform: translateY(0);
      width: 72.5px;
    }
  }
  &::before {
    background-image: url("#{mixin.set_image_path('top/icon_title_flag1_1.svg')}");
    left: 0;

    @include mixin.mq-tbP {
      left: -30px;
    }
  }
  &::after {
    background-image: url("#{mixin.set_image_path('top/icon_title_flag1_2.svg')}");
    right: 0;
    @include mixin.mq-tbP {
      right: -30px;
    }
  }
}
.p-top-section1-title__inner br {
  display: none;

  @include mixin.mq-tbL {
    display: inline;
  }
}

.p-top-section1__text {
  @include mixin.fz(15);
  line-height: calc(36 / 15);
  position: relative;
  text-align: center;
  z-index: 1;

  @include mixin.mq-tbP {
    line-height: 1.5;
    text-align: left;
  }
}

.p-top-section1__image1 {
  bottom: 50px;
  left: -75px;
  position: absolute;

  @include mixin.mq-tbP {
    display: none;
  }
}
.p-top-section1__image2 {
  bottom: 38px;
  position: absolute;
  right: -77px;

  @include mixin.mq-tbL {
    bottom: 0;
  }

  @include mixin.mq-tbP {
    margin-top: 20px;
    position: relative;
    right: 0;
    text-align: center;
  }
}

.p-top-section1__buttons {
  margin-top: 50px;
  text-align: center;

  @include mixin.mq-tbP {
    margin-top: 25px;
  }
}

.p-top-news {
  background-color: variables.$COLOR_MAIN;
  padding: 70px 0;

  @include mixin.mq-tbP {
    padding: 35px 0;
  }
}
.p-top-news__inner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.p-top-news__header {
  width: 270px;

  @include mixin.mq-tbL {
    width: 100%;
  }
}

.p-top-news-title {
  font-family: variables.$FONT_M_PLUS;
  @include mixin.fz(34);
  letter-spacing: 0.1em;

  @include mixin.mq-tbL {
    margin-bottom: 20px;
    text-align: center;
  }

  @include mixin.mq-tbP {
    @include mixin.fz(20);
  }
}
.p-top-news-title__inner {
  position: relative;

  &::before,
  &::after {
    background: {
      size: contain;
      position: center;
      repeat: no-repeat;
    }
    content: '';
    position: absolute;
  }

  &::before {
    background: {
      image: url("#{mixin.set_image_path('top/icon_title_star1.svg')}");
    }
    height: 57px;
    left: -75px;
    top: -35px;
    width: 87px;

    @include mixin.mq-tbP {
      height: 37px;
      left: -50px;
      top: -20px;
      width: 57px;
    }
  }

  &::after {
    background: {
      image: url("#{mixin.set_image_path('top/icon_title_star2.svg')}");
    }
    bottom: -10px;
    height: 16px;
    right: -48px;
    width: 18px;

    @include mixin.mq-tbP {
      bottom: -5px;
      height: 10px;
      right: -28px;
      width: 12px;
    }
  }
}

.p-top-news__body {
  padding-right: 110px;
  position: relative;
  width: calc(100% - 270px);

  @include mixin.mq-tbL {
    padding-right: 0;
    width: 100%;
  }
}
.p-top-news__more {
  bottom: 0;
  position: absolute;
  right: 0;

  @include mixin.mq-tbL {
    margin-top: 15px;
    position: relative;
    text-align: right;
  }
}

.p-top-section2 {
  background: {
    image: url("#{mixin.set_image_path('top/bg2_pc.png')}");
    repeat: no-repeat;
    position: center bottom;
    size: cover;
  }
  padding: 110px 0;

  @include mixin.mq-tbP {
    background-size: 1500px auto;
    padding: 50px 0;
  }
}

.p-top-section2__block:nth-child(n + 2) {
  margin-top: 150px;

  @include mixin.mq-tbP {
    margin-top: 120px;
  }
}

.p-top-section2__buttons {
  margin-top: 55px;
  text-align: center;

  @include mixin.mq-tbP {
    margin-top: 25px;
  }
}

.p-top-section2-title {
  font-family: variables.$FONT_M_PLUS;
  @include mixin.fz(34);
  letter-spacing: 0.1em;
  margin-bottom: 40px;
  text-align: center;

  @include mixin.mq-tbP {
    @include mixin.fz(20);
    line-height: 1.4;
    margin-bottom: 30px;
  }
}
.p-top-section2-title__inner {
  display: inline-block;
  position: relative;

  &::before,
  &::after {
    background: {
      size: contain;
      position: center;
      repeat: no-repeat;
    }
    content: '';
    position: absolute;
  }
}
.p-top-section2-title1__inner {
  padding: 0 170px;
  @include mixin.mq-tbP {
    padding: 0;
  }

  &::before,
  &::after {
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    background-image: url("#{mixin.set_image_path('top/icon_title_note1.svg')}");
    height: 43px;
    left: 0;
    width: 98px;
    @include mixin.mq-tbP {
      height: 32px;
      left: -70px;
      width: 56px;
    }
  }
  &::after {
    background-image: url("#{mixin.set_image_path('top/icon_title_note2.svg')}");
    height: 47px;
    right: 0;
    width: 94px;

    @include mixin.mq-tbP {
      height: 32px;
      right: -70px;
      width: 56px;
    }
  }
}
.p-top-section2-title2__inner {
  padding: 0 200px;
  @include mixin.mq-tbP {
    padding: 0;
  }

  &::before {
    background-image: url("#{mixin.set_image_path('top/icon_title_balloon1.svg')}");
    bottom: -20px;
    height: 140px;
    left: 0;
    width: 99px;

    @include mixin.mq-tbP {
      bottom: -10px;
      left: -75px;
      height: 84px;
      width: 58px;
    }
  }
  &::after {
    background-image: url("#{mixin.set_image_path('top/icon_title_balloon2.svg')}");
    bottom: -20px;
    height: 80px;
    right: 0;
    width: 44px;

    @include mixin.mq-tbP {
      bottom: -10px;
      height: 55px;
      right: -40px;
      width: 26px;
    }
  }
}

.p-top-section2-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -30px;

  @include mixin.mq-tbL {
    justify-content: flex-start;
    margin: 0 -10px;
  }

  @include mixin.mq-tbP {
    margin: 0 -8px;
  }
}
.p-top-section2-list__item {
  padding: 0 30px;
  width: 33.33333%;

  @include mixin.mq-tbL {
    padding: 0 10px;
  }

  @include mixin.mq-tbP {
    padding: 0 8px;
  }

  @include mixin.mq-tbP {
    width: 50%;
  }
}
.p-top-section2-list__item:nth-child(n + 4) {
  margin-top: 40px;

  @include mixin.mq-tbL {
    margin-top: 30px;
  }
}
.p-top-section2-list__item:nth-child(n + 3) {
  @include mixin.mq-tbP {
    margin-top: 20px;
  }
}
.p-top-section2-list__target {
  display: block;
  text-decoration: none;
}

.p-top-section2-card {
  text-align: center;
}
.p-top-section2-card__image {
  border: 8px solid variables.$COLOR_WHITE;

  @include mixin.mq-tbP {
    border-width: 3px;
  }
}
.p-top-section2-card__caption {
  @include mixin.fz(20);
  line-height: 1.2;
  margin-top: 20px;

  @include mixin.mq-tbP {
    @include mixin.fz(13);
    margin-top: 10px;
  }
}
