@use "variables" as variables;

@mixin mq-pc() {
  @media only screen and (max-width: map_get(variables.$BRAKE_POINT, pc)) {
    @content;
  }
}

@mixin mq-pc-min() {
  @media only screen and (min-width: #{map_get(variables.$BRAKE_POINT, pc) + 1}) {
    @content;
  }
}

@mixin mq-tbL() {
  @media only screen and (max-width: map_get(variables.$BRAKE_POINT, tbL)) {
    @content;
  }
}

@mixin mq-tbL-min() {
  @media only screen and (min-width: #{map_get(variables.$BRAKE_POINT, tbL) + 1}) {
    @content;
  }
}

@mixin mq-tbP() {
  @media only screen and (max-width: map_get(variables.$BRAKE_POINT, tbP)) {
    @content;
  }
}

@mixin mq-tbP-min() {
  @media only screen and (min-width: #{map_get(variables.$BRAKE_POINT, tbP) + 1}) {
    @content;
  }
}

@mixin mq-sp() {
  @media only screen and (max-width: map_get(variables.$BRAKE_POINT, sp)) {
    @content;
  }
}

@mixin mq-sp-min() {
  @media only screen and (min-width: #{map_get(variables.$BRAKE_POINT, sp) + 1}) {
    @content;
  }
}

@mixin mq-max($max) {
  @media only screen and (max-width: #{$max}px) {
    @content;
  }
}

@mixin mq-min($min) {
  @media only screen and (min-width: #{$min}px) {
    @content;
  }
}

// font-size
@mixin fz($size: map_get(variables.$BASE, font-size), $important: false) {
  font-size: if($important, ($size / 16) + rem !important, ($size / 16) + rem);
}

@function get_vw($size, $viewport) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size: 10, $viewport: 750) {
  font-size: get_vw($font_size, $viewport);
}

@mixin vw($property: font-size, $size: 10, $viewport: 750) {
  #{$property}: get_vw($size, $viewport);
}

@mixin animation($animation-name, $s, $easing, $count, $end: none, $delay: 0) {
  animation: {
    delay: $delay;
    duration: $s;
    fill-mode: $end;
    iteration-count: $count;
    name: $animation-name;
    timing-function: $easing;
  }
}

@mixin accessibilityHidden() {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// webFont icon
@mixin iconFont() {
  font-family: FontAwesome, serif;
  line-height: 1;
}

@mixin iconMaterial($size: 24) {
  direction: ltr;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  letter-spacing: normal;
  line-height: 1;
  white-space: nowrap;
  word-wrap: normal;
  font: {
    family: variables.$FONT_ICON;
    feature-settings: 'liga';
    style: normal;
    weight: normal;
  }
  @include fz($size);
}

@function set_common_path($fileName, $path: '../') {
  @if ($fileName) {
    @return '#{$path}img/common/#{$fileName}';
  } @else {
    @error '$fileNameがありません。';
  }
}

@function set_image_path($fileName, $path: '../') {
  @if ($fileName) {
    @return '#{$path}img/#{$fileName}';
  } @else {
    @error '$fileNameがありません。';
  }
}
@mixin object-fit($value: fill, $position: null) {
  object-fit: $value;

  @if $position {
    font-family: 'object-fit: #{$value}; object-position: #{$position}', sans-serif;
    object-position: $position;
  } @else {
    font-family: 'object-fit: #{$value}', sans-serif;
  }
}
